// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-speaker-js": () => import("./../../../src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-templates-sponsors-js": () => import("./../../../src/templates/sponsors.js" /* webpackChunkName: "component---src-templates-sponsors-js" */)
}

